import Environment from './types/environment';

const nonEmptyOrUndefined = (value: string | undefined): string | undefined =>
  value?.trim() || undefined;

export interface ClientConfig {
  siteUrl: string;
  apiUrl: string;
  environment: Environment;
  facebookDomainVerificationId?: string;
  googleTagManagerId?: string;
  googleTagManagerCustomServerUrl?: string;
  imboxId?: string;
  webflowSiteUrl: string;
  vwoId?: string;
  ozempicProductId: string;
  isReferralProgramEnabled: boolean;
}

export interface ServerConfig extends ClientConfig {}

export const clientConfig: ClientConfig = {
  siteUrl: process.env.NEXT_PUBLIC_SITE_URL || '',
  apiUrl: process.env.NEXT_PUBLIC_API_URL || '',
  environment: (process.env.NEXT_PUBLIC_ENVIRONMENT || 'local') as Environment,
  facebookDomainVerificationId: nonEmptyOrUndefined(
    process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_ID
  ),
  googleTagManagerId: nonEmptyOrUndefined(process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID),
  googleTagManagerCustomServerUrl:
    process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CUSTOM_SERVER_URL ||
    'https://www.googletagmanager.com',
  webflowSiteUrl: process.env.NEXT_PUBLIC_WEBFLOW_SITE_URL || '',
  imboxId: nonEmptyOrUndefined(process.env.NEXT_PUBLIC_IMBOX_ID),
  vwoId: nonEmptyOrUndefined(process.env.NEXT_PUBLIC_VWO_ID),
  ozempicProductId: process.env.NEXT_PUBLIC_OZEMPIC_PRODUCT_ID || '',
  isReferralProgramEnabled: process.env.NEXT_PUBLIC_REFERRAL_PROGRAM_ENABLED === 'true',
};

export const serverConfig: ServerConfig = {
  ...clientConfig,
};
