/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatState, ChatStaticMessage } from './chat.state';

const chatInitialState: ChatState = {
  messagesQueue: [],
};

const chat = createSlice({
  name: 'chat',
  initialState: chatInitialState,
  reducers: {
    addMessageToQueue: (state, action: PayloadAction<ChatStaticMessage>) => {
      state.messagesQueue.push(action.payload);
    },
    updateMessageFromQueue: (
      state,
      action: PayloadAction<{ id: string; data: Partial<ChatStaticMessage> }>
    ) => {
      const { id, data } = action.payload;
      const index = state.messagesQueue.findIndex(message => message.id === id);
      if (index > -1) {
        state.messagesQueue[index] = { ...state.messagesQueue[index], ...data };
      }
    },
    removeMessageFromQueue: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.messagesQueue = state.messagesQueue.filter(message => message.id !== id);
    },
  },
});

export const { actions } = chat;

export default chat.reducer;
