/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, ImageViewerParams } from './app.state';
import { LeadSource } from '../../entities/shared/lead';

const appInitialState: AppState = {
  isCTAPopupVisible: false,
  leadSource: {},
  imageViewerParams: null,
  referralCode: undefined,
  isReferralProgramWidgetVisible: true,
  isOnboardingUnlocked: undefined,
};

const app = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setIsCTAPopupVisible: (state, action: PayloadAction<boolean>) => {
      state.isCTAPopupVisible = action.payload;
    },
    setLeadSource: (state, action: PayloadAction<LeadSource>) => {
      state.leadSource = action.payload;
    },
    setImageViewerParams: (state, action: PayloadAction<ImageViewerParams | null>) => {
      state.imageViewerParams = action.payload;
    },
    setReferralCode: (state, action: PayloadAction<string | null>) => {
      state.referralCode = action.payload;
    },
    setIsReferralProgramWidgetVisible: (state, action: PayloadAction<boolean>) => {
      state.isReferralProgramWidgetVisible = action.payload;
    },
    setIsOnboardingUnlocked: (state, action: PayloadAction<boolean>) => {
      state.isOnboardingUnlocked = action.payload;
    },
  },
});

export const { actions } = app;

export default app.reducer;
