import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { i18n } from 'next-i18next';
import * as Sentry from '@sentry/nextjs';
import { toastr } from 'react-redux-toastr';
import defaultConfig from './api.config';
import { XSRF_COOKIE_NAME } from '../constants';

export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';

const createApiClient = (
  signOutCallback: () => void,
  apiConfig: AxiosRequestConfig = defaultConfig
): AxiosInstance => {
  const apiClient = axios.create({
    ...apiConfig,
    xsrfCookieName: XSRF_COOKIE_NAME,
    xsrfHeaderName: XSRF_HEADER_NAME,
    withXSRFToken: true,
  });

  apiClient.interceptors.request.use(originalConfig => {
    const config = { ...originalConfig };
    config.headers.Accept = 'application/json';
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
  });

  apiClient.interceptors.response.use(
    response => response,
    (error: any) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403 || error.response?.status === 401) {
          signOutCallback();
        }
        if (error.response?.status === 412 && i18n) {
          toastr.error(i18n.t('API_ERRORS.412.TITLE'), i18n.t('API_ERRORS.412.DESCRIPTION'));
        }
      }
      Sentry.captureException(error);
      throw error;
    }
  );

  return apiClient;
};

export default createApiClient;
