export enum PurchaseOptionType {
  OneTime = 'oneTime',
  Subscription = 'subscription',
  PrescriptionOnly = 'prescriptionOnly',
}

export enum SubscriptionIntervalUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export interface SubscriptionPreferences {
  chargeIntervalFrequency: number;
  orderIntervalFrequency: number;
  intervalUnit: SubscriptionIntervalUnit;
}

export interface OneTimePurchaseOption {
  type: PurchaseOptionType.OneTime;
}

export interface SubscriptionPurchaseOption {
  type: PurchaseOptionType.Subscription;
  subscriptionPreferences: SubscriptionPreferences;
}

export interface PrescriptionOnlyPurchaseOption {
  type: PurchaseOptionType.PrescriptionOnly;
}

export type PurchaseOption =
  | OneTimePurchaseOption
  | SubscriptionPurchaseOption
  | PrescriptionOnlyPurchaseOption;
