import BaseService from './base-service';
import { Address, UserContact } from '../../entities/user.entity';
import { CustomerPortal } from '../../entities/commerce/customer-portal.entity';
import { RequestBodyType } from '../../types/request-body-type';
import { ReferralCodeStats } from '../../types/referral-code-stats';
import { MyUser } from '../../types/my-user';

export type UpdateMeRequestBody = RequestBodyType<
  Pick<MyUser, 'preferredName' | 'jobTitle' | 'preferences'>
>;

export interface UpdateEmailRequestBody {
  email: string;
  sendVerificationEmail: boolean;
}

class MeService extends BaseService {
  getMe(): Promise<MyUser> {
    return this._apiClient.get('/api/rest/v1/me').then(response => response.data);
  }

  updateMe(data: UpdateMeRequestBody): Promise<MyUser> {
    return this._apiClient.patch('/api/rest/v1/me', data).then(response => response.data);
  }

  getCustomerPortal(): Promise<CustomerPortal> {
    return this._apiClient.get('/api/rest/v1/me/customer-portal').then(response => response.data);
  }

  /**
   * Updates the phone number and send the verification code if needed
   * @param phoneNumber
   */
  setPhoneNumber(phoneNumber: string): Promise<UserContact> {
    return this._apiClient
      .put('/api/rest/v1/me/phone-number', { phoneNumber })
      .then(response => response.data);
  }

  verifyPhoneNumber(code: string): Promise<UserContact> {
    return this._apiClient
      .post('/api/rest/v1/me/phone-number/verify', { code })
      .then(response => response.data);
  }

  /**
   * Updates the email and sends the verification email if needed
   * @param {Object} data - Request body sent to Ayd API
   * @param {string} data.email - New email to set
   * @param {boolean} data.sendVerificationEmail - Whether to send out the verification email
   */
  setEmail(data: UpdateEmailRequestBody): Promise<UserContact> {
    return this._apiClient.put('/api/rest/v1/me/email', data).then(response => response.data);
  }

  updateAddress(data: Address): Promise<MyUser> {
    return this._apiClient.put('/api/rest/v1/me/address', data).then(response => response.data);
  }

  getReferralCodeStats(): Promise<ReferralCodeStats> {
    return this._apiClient
      .get('/api/rest/v1/me/referral-code-stats')
      .then(response => response.data);
  }

  applyReferralCode(referralCode: string): Promise<void> {
    return this._apiClient
      .post('/api/rest/v1/me/apply-referral-code', { referralCode })
      .then(response => response.data);
  }
}

export default MeService;
