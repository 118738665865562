import { LineItemOption } from './line-item-option.entity';

export interface OrderLineItem {
  productId: number;
  variantId: number;
  name: string | null;
  sku: string;
  quantity: number;
  price: number;
  total: number;
  options: LineItemOption[];
}

interface OrderAddress {
  firstName: string | null;
  lastName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  countryCode: string | null;
  phone: string | null;
  email: string | null;
}

export interface OrderBillingAddress extends OrderAddress {}

export interface OrderShippingAddress extends OrderAddress {
  shippingMethod: string;
  baseCost: number;
  costExTax: number;
  costIncTax: number;
  costTax: number;
  baseHandlingCost: number;
  handlingCostExTax: number;
  handlingCostIncTax: number;
  handlingCostTax: number;
}

export interface OrderShipment {
  orderAddressId: number;
  dateCreated: string;
  trackingNumber: string | null;
  shippingMethod: string | null;
  comments: string | null;
  shippingProvider: string | null;
  trackingCarrier: string | null;
  trackingLink: string | null;
}

export enum OrderStatus {
  Incomplete = 'incomplete',
  Pending = 'pending',
  Shipped = 'shipped',
  PartiallyShipped = 'partiallyShipped',
  Refunded = 'refunded',
  Cancelled = 'cancelled',
  Declined = 'declined',
  AwaitingPayment = 'awaitingPayment',
  AwaitingPickup = 'awaitingPickup',
  AwaitingShipment = 'awaitingShipment',
  Completed = 'completed',
  AwaitingFulfillment = 'awaitingFulfillment',
  AwaitingDoctorApproval = 'awaitingDoctorApproval',
  Disputed = 'disputed',
  PartiallyRefunded = 'partiallyRefunded',
}

export enum PaymentStatus {
  Authorized = 'authorized',
  Captured = 'captured',
  CapturePending = 'capturePending',
  Declined = 'declined',
  HeldForReview = 'heldForReview',
  Paid = 'paid',
  PartiallyRefunded = 'partiallyRefunded',
  Pending = 'pending',
  Refunded = 'refunded',
  Void = 'void',
  VoidPending = 'voidPending',
}

export interface LeanOrder {
  id: number;
  customerId: number;
  billingAddress: OrderBillingAddress;
  createdAt: string;
  shippedAt: string | null;
  subtotalExTax: number;
  subtotalIncTax: number;
  subtotalTax: number;
  totalExTax: number;
  totalIncTax: number;
  totalTax: number;
  currency: string | null;
  cartId: string | null;
  paymentStatus: PaymentStatus | null;
  paymentMethod: string | null;
  isDigital: boolean;
  status: OrderStatus;
}

export interface Order extends LeanOrder {
  items: OrderLineItem[];
  shippingAddress: OrderShippingAddress | null;
  shipment: OrderShipment | null;
}
