import { AxiosInstance } from 'axios';
import React from 'react';
import { useQueryClient } from 'react-query';
import createApiClient from '../api/api';
import { ME_QUERY_KEY } from './use-me-query';

const useApiClient = (): AxiosInstance => {
  const queryClient = useQueryClient();
  return React.useMemo(
    () => createApiClient(() => queryClient.setQueryData(ME_QUERY_KEY, null)),
    [queryClient]
  );
};

export default useApiClient;
