import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useMeQuery from '../../model/hooks/use-me-query';
import buildLocalizedPath from '../../utils/build-localized-path/build-localized-path';

interface AppContainerProps {}

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const router = useRouter();
  const { locale, asPath, defaultLocale } = router;
  const { data: user } = useMeQuery();

  useEffect(() => {
    if (user && user.preferences.language !== locale) {
      const newPath = buildLocalizedPath({
        path: asPath,
        defaultLocale,
        locale: user.preferences.language,
      });
      window.location.replace(newPath);
    }
  }, [user, locale, asPath, defaultLocale]);

  // Don't add div here! It breaks the quiz
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AppContainer;
