import { AxiosInstance } from 'axios';

class BaseService {
  protected _apiClient: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this._apiClient = apiClient;
  }
}

export default BaseService;
