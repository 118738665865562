import ICU from 'i18next-icu';
import { UserConfig } from 'next-i18next';
import i18n from './i18n.config';

const config: UserConfig = {
  use: [new ICU()],
  serializeConfig: false,
  i18n,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
};

export default config;
