import { UseQueryResult, useQuery, UseQueryOptions } from 'react-query';
import useApiClient from './use-api-client';
import MeService from '../api/services/me.service';
import { MyUser } from '../types/my-user';

export const ME_QUERY_KEY = 'me';

const useMeQuery = (
  options: UseQueryOptions<MyUser> | undefined = undefined
): UseQueryResult<MyUser> => {
  const apiClient = useApiClient();

  return useQuery<MyUser>(ME_QUERY_KEY, () => new MeService(apiClient).getMe(), {
    staleTime: Infinity,
    ...options,
  });
};

export default useMeQuery;
