import { PurchaseOption } from './purchase-option';

export interface ProductImage {
  standard: string;
  thumbnail: string;
}

export interface ProductVariantOptions {
  strength?: string;
  size?: string;
  purchaseOption?: PurchaseOption;
}

export enum DoseType {
  Low = 'low',
  Normal = 'normal',
  High = 'high',
}

export interface ProductVariantAttributes {
  doseType?: DoseType;
  mostPopular?: boolean;
  bestPrice?: boolean;
  description?: string;
  sortOrder?: number;
  serviceFeePrice?: number;
  orderFrequencyTitle?: string;
  packageDuration?: number;
  priceBreakdownOverride?: string;
}

export enum ProductModifierName {
  PrescribedMedications = 'prescribedMedications',
}

export interface ProductModifier {
  id: number;
  name: ProductModifierName;
}

export interface ProductVariant {
  id: number;
  productId: number;
  sku: string;
  price: number;
  active: boolean;
  options: ProductVariantOptions;
  attributes: ProductVariantAttributes;
  cover: string | null;
}

export enum ProductCategory {
  ErectileDysfunction = 'erectileDysfunction',
  HairLoss = 'hairLoss',
  Skincare = 'skincare',
  ErectileDysfunctionAsNeeded = 'erectileDysfunctionAsNeeded',
  ErectileDysfunctionDaily = 'erectileDysfunctionDaily',
  WeightLoss = 'weightLoss',
  PrematureEjaculation = 'prematureEjaculation',
  PrematureEjaculationAsNeeded = 'prematureEjaculationAsNeeded',
  PrematureEjaculationDaily = 'prematureEjaculationDaily',
}

export interface ProductAttributes {
  baseServiceFeePrice?: number;
  productHeader?: string;
  extraInformation?: string;
  sortOrder?: number;
  hidden?: boolean;
}

export interface Product {
  id: number;
  sku: string;
  name: string;
  price: number;
  isRx: boolean;
  isDigital: boolean;
  active: boolean;
  description: string | null;
  cover: ProductImage | null;
  images: ProductImage[];
  variants: ProductVariant[];
  categories: ProductCategory[];
  modifiers: ProductModifier[];
  attributes: ProductAttributes;
}

export type ExtendedProductVariant = ProductVariant & { product: Omit<Product, 'variants'> };
