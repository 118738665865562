import { CookieAttributes } from 'js-cookie';

const getCookieAttributes = (expires: number): CookieAttributes => {
  if (typeof document === 'undefined') {
    return { expires };
  }
  return {
    expires,
    domain: `.${window.location.hostname}`,
    sameSite: 'lax',
    secure: window.location.protocol === 'https:',
  };
};

export default getCookieAttributes;
