/* eslint-disable no-param-reassign */
import axios from 'axios';
import { Event, EventHint } from '@sentry/nextjs';

const sentryBeforeSend = (event: Event, hint: EventHint) => {
  const exception = hint.originalException;

  if (axios.isAxiosError(exception)) {
    if (!exception.response || exception.response.status < 500) {
      return null;
    }
    event.extra = {
      ...event.extra,
      config: exception.config && {
        baseURL: exception.config.baseURL,
        url: exception.config.url,
        method: exception.config.method,
        params: exception.config.params,
      },
      code: exception.code,
      status: exception.response.status,
      data: exception.response.data,
    };
    event.tags = {
      ...event.tags,
      axios: true,
    };
  }

  return event;
};

export default sentryBeforeSend;
