import { combineReducers } from '@reduxjs/toolkit';
import { reducer as toastr } from 'react-redux-toastr';
import appReducer from '../app/app.slice';
import chatReducer from '../chat/chat.slice';

const rootReducer = combineReducers({
  toastr,
  app: appReducer,
  chat: chatReducer,
});

export default rootReducer;
